/**
 * @generated SignedSource<<750f7141797200b3f8157e24c4b18d60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersApplicationBase$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPlusPage" | "helpersCheckIsPostiesArticle" | "helpersCheckIsPostiesPage">;
  readonly " $fragmentType": "helpersApplicationBase";
};
export type helpersApplicationBase$key = {
  readonly " $data"?: helpersApplicationBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersApplicationBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersApplicationBase"
};

(node as any).hash = "cb06a24d019d640f70986b6cdbe1a4b6";

export default node;
