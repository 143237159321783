import { graphql, readInlineData } from "react-relay";

import { checkIsLongReadArticle } from "~/components/article/article-render/long-read-article/helpers";
import { checkIsMorningStudioArticle } from "~/components/article/article-render/morning-studio-article/helpers";
import { checkIsPhotoEssayArticle } from "~/components/article/article-render/photo-essay-article/helpers";
import { checkIsPlusArticle } from "~/components/article/article-render/plus/helpers";
import { checkIsPostiesArticle } from "~/components/article/article-render/posties/helpers";
import { checkIsStyleArticle } from "~/components/article/article-render/style-article/helper";
import { checkIsPostiesPage } from "~/components/pages/posties-pages/helpers";
import { checkIsSectionContent, checkIsStyleSectionContent } from "~/components/section/helpers";
import { appBarConfiguration as sectionStyleAppBarConfiguration } from "~/components/section/section-style/const";
import type { helpersAppBarAdStatusBase$key } from "~/queries/__generated__/helpersAppBarAdStatusBase.graphql";
import type { helpersAppBarVariantBase$key } from "~/queries/__generated__/helpersAppBarVariantBase.graphql";
export type AppBarVariant =
  | "posties/brochure"
  | "posties/generic"
  | "scmp/generic-light"
  | "scmp/generic-dark"
  | "scmp/home"
  | "scmp/home-slim"
  | "scmp/magazines-style"
  | "scmp/plus"
  | "scmp/scroll-over";

export const AppBarColorScheme: Record<AppBarVariant, "light" | "dark"> = {
  "posties/brochure": "light",
  "posties/generic": "light",
  "scmp/generic-dark": "dark",
  "scmp/generic-light": "light",
  "scmp/home": "light",
  "scmp/home-slim": "light",
  "scmp/magazines-style": "light",
  "scmp/plus": "light",
  "scmp/scroll-over": "dark",
};

export const getAppBarVariant = (reference: helpersAppBarVariantBase$key) => {
  const content = readInlineData(
    graphql`
      fragment helpersAppBarVariantBase on Base @inline {
        ...helpersCheckIsLongReadArticle
        ...helpersCheckIsMorningStudioArticle
        ...helpersCheckIsPhotoEssayArticle
        ...helpersCheckIsPlusArticle
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsPostiesPage
        ...helpersCheckIsStyleArticle
        ...helpersSectionContent
        ...helpersStyleSectionContent
        ...helpersCheckIsPlusPage
      }
    `,
    reference,
  );

  if (checkIsStyleArticle(content) || checkIsStyleSectionContent(content))
    return sectionStyleAppBarConfiguration.appBarVariant;
  if (checkIsSectionContent(content)) return "scmp/home-slim";
  if (checkIsPhotoEssayArticle(content) || checkIsLongReadArticle(content))
    return "scmp/scroll-over";
  if (checkIsMorningStudioArticle(content)) return "scmp/generic-dark";
  if (checkIsPostiesArticle(content) || checkIsPostiesPage(content)) return "posties/generic";
  if (checkIsPlusArticle(content)) return "scmp/plus";

  return "scmp/generic-light";
};

export type AppBarAdStatus = "Enabled" | undefined;
export const getAppBarAdStatusByContent = (reference: helpersAppBarAdStatusBase$key) => {
  const content = readInlineData(
    graphql`
      fragment helpersAppBarAdStatusBase on Base @inline {
        ... on Section {
          __typename
          ...helpersStyleSectionContent
        }
      }
    `,
    reference,
  );

  return content.__typename === "Section" && checkIsStyleSectionContent(content)
    ? sectionStyleAppBarConfiguration.appBarAdStatus
    : undefined;
};
