/**
 * @generated SignedSource<<894de27daa4a0068fff56f45dba88a5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersAppBarVariantBase$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsLongReadArticle" | "helpersCheckIsMorningStudioArticle" | "helpersCheckIsPhotoEssayArticle" | "helpersCheckIsPlusArticle" | "helpersCheckIsPlusPage" | "helpersCheckIsPostiesArticle" | "helpersCheckIsPostiesPage" | "helpersCheckIsStyleArticle" | "helpersSectionContent" | "helpersStyleSectionContent">;
  readonly " $fragmentType": "helpersAppBarVariantBase";
};
export type helpersAppBarVariantBase$key = {
  readonly " $data"?: helpersAppBarVariantBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersAppBarVariantBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersAppBarVariantBase"
};

(node as any).hash = "09c81e111f10d01b4b54b0a2b7522d27";

export default node;
