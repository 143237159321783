import pick from "lodash/pick";
import type { LinkProps } from "next/link";
import Link from "next/link";
import qs from "qs";
import type { CSSProperties, ReactNode } from "react";
import { forwardRef } from "react";

import { tracking } from "~/data";
import { useRouteDetector } from "~/lib/router/hooks";

import { useBaseLinkContext } from "./context";
import { StyledAnchor } from "./styles";

export type Props = {
  anchorProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  as?: string;
  children?: ReactNode;
  className?: string;
  hash?: string;
  isAnchor?: boolean;
  pathname: string;
  query?: ExternalQuery & InternalQuery;
  style?: CSSProperties;
  target?: string;
} & Omit<LinkProps, "href" | "as" | "passHref">;

// InternalQuery is query string used internally and should not be shown in the href
type InternalQuery = Record<string, string | undefined>;

// ExternalQuery is query string used externally and should shown in the href
// Example Usage: GTM variables: `Query String - House Ad`
type ExternalQuery = Partial<{
  CMCampaignID: string;
  UUID: string; // eslint-disable-line @typescript-eslint/naming-convention
  campaign: string;
  module: string;
  origin: string;
  pgtype: string;
}>;
const externalQueryTypeTemplate = {
  campaign: "",
  module: tracking.module.Inline,
  origin: "",
  pgtype: tracking.pageType.Article,
};
const validExternalQueryKeys = Object.keys(externalQueryTypeTemplate);

export const BaseLink = forwardRef<HTMLAnchorElement, Props>(
  (
    { anchorProps, children, className, hash, isAnchor, onClick, pathname, query, style, ...props },
    reference,
  ) => {
    const { customQueryParameters } = useBaseLinkContext();
    const { isMigratedRoute } = useRouteDetector();

    const renderAnchor = (href?: string) => (
      <StyledAnchor
        {...anchorProps}
        className={className}
        href={href}
        ref={reference}
        style={style}
        onClick={onClick}
      >
        {children}
      </StyledAnchor>
    );

    const mergedQuery = { ...customQueryParameters, ...query };

    // Need to keep the external query string in the display url for GTM to pick up the values
    const externalQueryString = qs.stringify(pick(mergedQuery, validExternalQueryKeys), {
      addQueryPrefix: true,
    });
    const asUrl = `${props.as ?? pathname}${externalQueryString}${hash ?? ""}`;

    if (!isMigratedRoute(asUrl) || isAnchor) {
      return renderAnchor(asUrl);
    }

    return (
      <Link
        {...props}
        {...anchorProps}
        as={asUrl}
        className={className}
        href={{ hash, pathname, query: mergedQuery }}
        passHref={true}
        style={style}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  },
);

BaseLink.displayName = "BaseLink";
