import { graphql, readInlineData } from "react-relay";

import { article as articleData } from "~/data";
import type { helpersCheckIsPhotoEssayArticle$key } from "~/queries/__generated__/helpersCheckIsPhotoEssayArticle.graphql";

export const checkIsPhotoEssayArticle = (reference: helpersCheckIsPhotoEssayArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsPhotoEssayArticle on Article @inline {
        articleTypes: types {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  const articleTypes =
    article.articleTypes?.flatMap(
      types => types?.value?.map(value => value?.entityId ?? "") ?? [],
    ) ?? [];
  return articleTypes?.some(id => id === articleData.types.photoEssay.entityId) ?? false;
};
