/**
 * @generated SignedSource<<1df80be9e33a2d762fefcf7f823812bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type entityLink$data = {
  readonly __typename: string;
  readonly application: {
    readonly entityId: string;
  } | null | undefined;
  readonly entityId: string;
  readonly entityUuid: string;
  readonly fullSectionPath?: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly urlRedirect: {
    readonly toUrl: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersAppBarAdStatusBase" | "helpersAppBarVariantBase" | "helpersApplicationBase">;
  readonly " $fragmentType": "entityLink";
};
export type entityLink$key = {
  readonly " $data"?: entityLink$data;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityUuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Section",
    "kind": "LinkedField",
    "name": "fullSectionPath",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "Section",
  "abstractKey": null
},
v7 = {
  "alias": "articleTypes",
  "args": null,
  "concreteType": "ArticleTypeSegment",
  "kind": "LinkedField",
  "name": "types",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleType",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v7/*: any*/)
    ],
    "type": "Article",
    "abstractKey": null
  }
],
v9 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SectionSegment",
        "kind": "LinkedField",
        "name": "sections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Section",
            "kind": "LinkedField",
            "name": "value",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
],
v10 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPostiesArticle",
  "selections": (v9/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v11 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Page",
    "abstractKey": null
  }
],
v12 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPostiesPage",
  "selections": (v11/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v13 = [
  (v6/*: any*/)
],
v14 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPlusPage",
  "selections": (v11/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "entityLink",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UrlRedirect",
      "kind": "LinkedField",
      "name": "urlRedirect",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "toUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersAppBarVariantBase",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsLongReadArticle",
          "selections": (v8/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsMorningStudioArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sponsorType",
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPhotoEssayArticle",
          "selections": (v8/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPlusArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaywallType",
                  "kind": "LinkedField",
                  "name": "paywallTypes",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v10/*: any*/),
        (v12/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsStyleArticle",
          "selections": (v9/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSectionContent",
          "selections": (v13/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersStyleSectionContent",
          "selections": (v13/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        (v14/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersApplicationBase",
      "selections": [
        (v10/*: any*/),
        (v12/*: any*/),
        (v14/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersAppBarAdStatusBase",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersStyleSectionContent",
              "selections": (v5/*: any*/),
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "Section",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Base",
  "abstractKey": "__isBase"
};
})();

(node as any).hash = "dc304947da294d9eae3e089bfd0ef99a";

export default node;
