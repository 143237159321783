import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { getAppBarAdStatusByContent, getAppBarVariant } from "~/components/app-bar/helpers";
import { getApplication } from "~/components/app-initializer/helpers";
import { BaseEntityLink } from "~/components/base-entity-link";
import type { LinkEntity } from "~/components/base-entity-link/types";
import type { Props as BaseLinkProps } from "~/components/common/base-link";
import { getSectionRelatedSectionsQueueName } from "~/pages/section/helpers";
import type { entityLink$key } from "~/queries/__generated__/entityLink.graphql";

export type Props = {
  children?: ReactNode;
  reference: entityLink$key;
} & Omit<BaseLinkProps, "href" | "as" | "pathname">;

export const EntityLink = forwardRef<HTMLAnchorElement, Props>((props, reference) => {
  const { children, query, reference: reference_, ...remainingProps } = props;
  const linkableEntity = useFragment(
    graphql`
      fragment entityLink on Base {
        __typename
        entityId
        entityUuid
        urlAlias
        urlRedirect {
          toUrl
        }
        application {
          entityId
        }
        ... on Section {
          fullSectionPath {
            entityId
          }
        }
        ...helpersAppBarVariantBase
        ...helpersApplicationBase
        ...helpersAppBarAdStatusBase
      }
    `,
    reference_,
  );

  const {
    __typename,
    application,
    entityId,
    entityUuid,
    fullSectionPath = [],
    urlAlias,
    urlRedirect,
  } = linkableEntity;

  const linkEntity: LinkEntity = {
    applicationId: application?.entityId,
    entityId,
    routeType: __typename,
    urlAlias,
    urlRedirectPath: urlRedirect?.toUrl,
  };

  const additionalQueryParameters = {
    appBarAdStatus: getAppBarAdStatusByContent(linkableEntity),
    appBarVariant: getAppBarVariant(linkableEntity),
    application: getApplication(linkableEntity),
    relatedSectionsQueueName:
      __typename === "Section"
        ? getSectionRelatedSectionsQueueName(fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [])
        : undefined,
  };

  return (
    <BaseEntityLink
      linkEntity={linkEntity}
      {...remainingProps}
      query={{
        ...query,
        ...additionalQueryParameters,
        entityUuid,
      }}
      ref={reference}
    >
      {children}
    </BaseEntityLink>
  );
});

EntityLink.displayName = "EntityLink";
