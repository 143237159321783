import { graphql, readInlineData } from "react-relay";

import type { helpersCheckIsPlusPage$key } from "~/queries/__generated__/helpersCheckIsPlusPage.graphql";

export const checkIsPlusPage = (reference: helpersCheckIsPlusPage$key) => {
  const data = readInlineData(
    graphql`
      fragment helpersCheckIsPlusPage on Page @inline {
        urlAlias
      }
    `,
    reference,
  );

  return data?.urlAlias?.includes("/plus");
};
