import { notEmpty, scmpPlus } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import type { helpersCheckIsPlusArticle$key } from "~/queries/__generated__/helpersCheckIsPlusArticle.graphql";

export const checkIsPlusArticle = (reference: helpersCheckIsPlusArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsPlusArticle on Article @inline {
        paywallTypes {
          entityUuid
        }
      }
    `,
    reference,
  );

  const paywallTypes = article?.paywallTypes?.map(paywallType => paywallType?.entityUuid);

  return notEmpty(paywallTypes?.includes(scmpPlus.article.paywallTypeId));
};
