export const HamburgerMenu = "hamburger_menu";
export const HardLink = "hard_link";
export const HardLinkPosties = "hard_link_posties";
export const Inline = "inline";
export const Article = "article";
export const InlineAuto = "inline_auto";
export const InlinePosties = "inline_posties";
export const Masthead = "masthead";
export const MegaMenuKnowledgeCard = "mega_menu_knowledge_card";
export const MyAccountOverlay = "my_account_overlay";
export const SCMPFavouritesLink = "scmp_favourites_link";
export const BackToHomepage = "back_to_homepage";
export const SeriesLHSMoreOn = "series_LHS_more_on";
export const SeriesLHSHeader = "series_LHS_header";
export const SeriesLHS = "series_LHS";
export const TopPicks = "top_picks";
export const MoreStoriesPosties = "more_stories_posties";
export const MyNewsText = "myNEWS_text";
export const SCMPEducation = "scmp_education";
export const GetEvenMoreWithPosties = "get_even_more_with_posties";
export const BigRead = "big_read";
export const EyeOnTheNews = "eye_on_the_news";
export const HaHaHappenings = "ha_ha_happenings";
export const HealthHappiness = "health_happiness";
export const SchoolOfTheWeek = "school_of_the_week";
export const SteamStudio = "steam_studio";
export const YourSay = "your_say";
export const FooterPosties = "footer_posties";
export const HeaderPosties = "header_posties";
export const HamburgerMenuPosties = "hamburger_menu_posties";

// For homepage
export const Homepage = "homepage";
export const TopStory = "top_story";
export const Latest = "latest";
export const FlexiUnitFocus = "flexi_unit-focus";
export const Opinion = "opinion";
export const HomepageGBA = "homepage_gba";
export const MyDaily5 = "my_daily_5";
export const MostPopular = "most_popular";
export const FromOurAdvertises = "from_our_advertisers";
export const AroundSCMP = "around_scmp";
export const Multimedia = "multimedia";
export const ThingsToDo = "things_to_do_hk";
export const Spotlight = "spotlight";
export const TrendingTopics = "trending_topics";
export const StoryPackage = "feature_package";
